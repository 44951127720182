<template>
  <div class="input-wrapper">
    <ValidationProvider v-slot="{ errors, valid }" :vid="vid" :name="label || name" :rules="rules">
      <b-field
        :type="{ 'is-danger': errors[0], 'is-success': valid && showValid }"
        :message="hideMessages ? '' : errors[0] ? errors : message"
        :label="label"
      >
        <b-input
          :id="id"
          :key="name"
          v-model="innerValue"
          :name="name"
          :class="{ 'hide-icons': hideIcons, 'hide-arrows': hideArrows }"
          :type="type"
          :step="type === 'number' ? step : null"
          :placeholder="placeholder"
          :disabled="disabled"
          :password-reveal="type === 'password' ? passwordReveal : false"
          :icon="icon"
          :min="type === 'number' ? min : null"
          :max="type === 'number' ? max : null"
          :icon-right="iconRight"
          :readonly="readonly"
          autocomplete="off"
          @keyup.enter.native="onKeyupEnter"
          @keyup.native="onKeyupInput"
        ></b-input>
      </b-field>
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'Input',
  components: {
    ValidationProvider,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    showValid: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    hideMessages: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconRight: {
      type: String,
      default: null,
    },
    hideIcons: {
      type: Boolean,
      default: false,
    },
    vid: {
      type: String,
      default: undefined,
    },
    value: {
      type: null,
      default: null,
    },
    step: {
      type: String,
      default: '1',
    },
    min: {
      type: [String, Number],
      default: 0,
    },
    max: {
      type: [String, Number],
      default: undefined,
    },
    passwordReveal: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideArrows: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerValue: this.value,
    };
  },
  watch: {
    innerValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('input', newValue);
      }
    },
    value(newValue) {
      this.innerValue = newValue;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    onKeyupEnter() {
      this.$emit('onkeyupenter');
    },
    onKeyupInput() {
      if (this.innerValue !== this.value && this.value !== null && this.value !== '') {
        this.innerValue = this.value;
      }
    },
  },
};
</script>

<style lang="scss">
.input-wrapper {
  @import '../theme/custom.scss';
  input {
    &.input {
      background: #ffffff;
      height: 60px;
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      color: #808285;
      font-size: 16px;
      outline: none;
      line-height: 20px;
      @include placeholder {
        color: #808285;
        font-size: 16px;
        outline: none;
        line-height: 20px;
      }
    }
  }
  .field {
    div.hide-icons {
      .icon.is-right {
        display: none;
      }
    }

    div.hide-arrows {
      input {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type='number'] {
          -moz-appearance: textfield;
        }
        text-align: center;
      }
    }

    p.help {
      margin: 0 !important;
      text-align: left !important;
      &.is-danger {
        color: #f14668 !important;
      }
    }

    span.icon {
      font-size: 10px;
      top: 50%;
      transform: translate(-10px, -50%);
    }
  }
}
</style>
