<template>
  <div class="container">
    <HomeVideo />
    <h1 style="margin: 60px 0 20px 0">Sobre o Projeto</h1>
    <div class="columns">
      <div class="column">
        <p>
          O PROJETO DESPERTAR faz parte de uma CORRENTE DO BEM propagada pela CDL Cuiabá e
          organizado pelo programa CDL Social.
        </p>
        <p>
          A edição deste ano é voltada para a preparação de jovens de 14 a 18 anos. Nosso objetivo é
          contribuir com a capacitação e inserção dos jovens no mercado de trabalho, promovendo a
          inclusão social, melhoria da qualidade de vida e permanência no mundo do trabalho.
        </p>
        <p>
          O projeto, em forma de workshop, será realizado por meio de módulos com o objetivo de
          instruir os jovens sobre as relações interpessoais e no trabalho, oficina de currículo,
          marketing pessoal e preparação para entrevistas de emprego, além de disponibilizar cursos
          on-line e auxiliar nas inscrições e encaminhamentos para vagas de emprego, exercitando
          assim o nosso papel social.
        </p>
        <p>
          Ao final dos módulos, com o certificado de participação e o currículo em mãos, o
          participante sairá mais preparado para iniciar a sua inserção ao mercado de trabalho.
        </p>
        <section class="mt-4">
          <h3>Condições de participação:</h3>
          <ul>
            <li>Preencher o formulário de cadastro completo no site;</li>
            <li>
              Comparecer com um documento de identificação e termo de participação na data e período
              agendado;
            </li>
          </ul>
        </section>
        <p>
          <small
            >Durante o workshop serão adotadas todas as medidas de biossegurança para
            atendimento.</small
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import HomeVideo from '@/components/HomeVideo.vue';

export default {
  name: 'About',
  components: {
    HomeVideo,
  },
};
</script>

<style scoped lang="scss">
h3 {
  font-weight: 800;
  font-size: 1.15rem;
  line-height: 40px;
  color: #05afeb;
}
ul {
  list-style-type: disc;
  padding-left: 15px;
}
p {
  small {
    text-decoration: underline;
  }
}
</style>
