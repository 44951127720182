import { required, confirmed, email } from 'vee-validate/dist/rules.umd';
import { extend } from 'vee-validate';
import { differenceInYears } from 'date-fns';

extend('required', {
  ...required,
  message: 'Este campo é obrigatório',
});

extend('email', {
  ...email,
  message: 'Insira um e-mail válido',
});

extend('confirmed', {
  ...confirmed,
  message: 'Os campos de confirmação não correspondem',
});

extend('required-to-be-true', {
  validate(value) {
    return value === true;
  },
  params: ['value'],
  message: 'Este campo é obrigatório',
});

extend('min', {
  validate(value, args) {
    const { value: min } = args;
    if (Number.isNaN(value)) {
      return value.length >= min;
    }
    return value >= min;
  },
  params: ['value'],
  message: (campo, parametros) => {
    if (Number.isNaN(parametros.value)) {
      return `${campo} deve ter no mínimo ${parametros.value} caracteres`;
    }
    return `${campo} deve ser maior que ${parametros.value}`;
  },
});

extend('cpf', {
  validate(value) {
    if (!value) return false;

    // Aceita receber o valor como string, número ou array com todos os dígitos
    const validTypes = typeof value === 'string' || Number.isInteger(value) || Array.isArray(value);

    // Elimina valores com formato inválido
    if (!validTypes) return false;

    // Guarda todos os dígitos em um array
    const numbers = value.toString().match(/\d/g).map(Number);

    // Valida quantidade de dígitos
    if (numbers.length !== 11) return false;

    // Elimina valores inválidos com todos os dígitos repetidos
    const items = [...new Set(numbers)];
    if (items.length === 1) return false;

    // Separa número base do dígito verificador
    const base = numbers.slice(0, 9);
    const digits = numbers.slice(9);

    // Cálculo base
    const calc = (n, i, x) => n * (x - i);

    // Utilitário de soma
    const sum = (r, n) => r + n;

    // Cálculo de dígito verificador
    const digit = n => {
      const rest = n % numbers.length;
      return rest < 2 ? 0 : numbers.length - rest;
    };

    // Cálculo sobre o número base
    const calc0 = base.map((n, i) => calc(n, i, numbers.length - 1)).reduce(sum, 0);
    // 1o. dígito verificador
    const digit0 = digit(calc0);

    // Valida 1o. digito verificador
    if (digit0 !== digits[0]) return false;

    // Cálculo sobre o número base + 1o. dígito verificador
    const calc1 = base
      .concat(digit0)
      .map((n, i) => calc(n, i, numbers.length))
      .reduce(sum, 0);
    // 2o. dígito verificador
    const digit1 = digit(calc1);

    // Valida 2o. dígito verificador
    return digit1 === digits[1];
  },
  params: ['value'],
  message: 'Informe um CPF válido',
});

extend('phone', {
  validate(value) {
    return /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/gm.test(
      value,
    );
  },
  message: 'Número de telefone inválido',
});

extend('14to19', {
  validate(value) {
    const years = differenceInYears(new Date(), value);
    return years >= 14 && years <= 19;
  },
  message: 'Somente entre 14 e 19 anos',
});
