import Vue from 'vue';
import Buefy from 'buefy';
import VueTheMask from 'vue-the-mask';
import VCalendar from 'v-calendar';
import VueExcelXlsx from 'vue-excel-xlsx';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App.vue';
import router from './router';
import Input from './components/Input.vue';
import './theme/buefy.custom.scss';
import 'vue-lazy-youtube-video/dist/style.css';
import './helpers/validations';
// internal icons

library.add(fas);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(Buefy, {
  defaultIconComponent: FontAwesomeIcon,
  defaultIconPack: 'fas',
});
Vue.use(Input);
Vue.config.productionTip = false;

Vue.use(VueExcelXlsx);
Vue.use(VCalendar, {
  componentPrefix: 'v',
});

Vue.use(VueTheMask);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
