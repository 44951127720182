<template>
  <div class="columns">
    <div class="column has-text-centered">
      <div class="video-content">
        <LazyYoutubeVideo :src="videoUrl" previewImageSize="maxresdefault">
          <template #button>
            <button class="play-button">
              <img src="@/assets/icons/playButton.svg" />
            </button>
          </template>
        </LazyYoutubeVideo>
      </div>
    </div>
  </div>
</template>

<script>
import LazyYoutubeVideo from 'vue-lazy-youtube-video';

export default {
  name: 'HomeVideo',
  components: { LazyYoutubeVideo },
  data() {
    return {
      videoUrl: 'https://www.youtube.com/embed/bB1bX6o8Jrg',
      // thumbnail: {
      //   webp: 'http://i3.ytimg.com/vi/Q-jbNoV3izo/maxresdefault.jpg',
      //   jpg: 'http://i3.ytimg.com/vi/Q-jbNoV3izo/maxresdefault.jpg',
      // },
    };
  },
};
</script>

<style lang="scss">
.y-video__media--type--img {
  filter: brightness(60%);
}
</style>
