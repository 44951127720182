import Vue from 'vue';
import VueRouter from 'vue-router';
import About from '../views/About.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'About',
    component: About,
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
  // {
  //   path: '/formulario',
  //   name: 'Form',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Form.vue'),
  // },
  // {
  //   path: '/andamento',
  //   name: 'UserStatus',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/UserStatus.vue'),
  // },
  // {
  //   path: '/interno/relatorio',
  //   name: 'Report',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Report.vue'),
  // },
  // {
  //   path: '/interno/relatorio-geral',
  //   name: 'GeneralReport',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/GeneralReport.vue'),
  // },
  // {
  //   path: '/interno/confirmar',
  //   name: 'Confirmation',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Confirmation.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
